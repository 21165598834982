import ExcelJS from 'exceljs';
import { pad } from '@rjsf/utils';
import { utcToSite } from './datetimeUtils';

const isItemDesignation = (itemDesignation) => {
  const pattern = /^([+=-])([a-zA-Z0-9]+[+-.=])*[a-zA-Z0-9]+$/;
  return pattern.test(itemDesignation);
};
class ParseExcelError extends Error {
  constructor(errors) {
    super('ParseExcelError');
    this.errors = errors;
  }
}

const buildMaintenanceMessageString = ({
  description,
  energized,
  operation,
  responsibility,
  noOfItems,
  eachItemDuration,
  noOfCrew,
  pc,
  pb,
  px,
  pv,
  pm,
  pe,
  pex,
  se,
  pg,
  avarageManHourPerYear,
  noOfMaintenananceDuringLifetime,
}) => {
  let updatedDescription;
  updatedDescription = `${description}\n\n`;
  updatedDescription += `Site energized: ${energized}. \n`;
  updatedDescription += operation ? `Operation: ${operation}. \n` : '';
  updatedDescription += responsibility
    ? `Responsibility: ${responsibility === 'C' ? 'Customer' : 'HE'}. \n`
    : '';
  updatedDescription += `Number of items: ${noOfItems.toString()}. \n`;
  updatedDescription += `Duration per item: ${
    eachItemDuration.result ? eachItemDuration.result.toString() : eachItemDuration.toString()
  } hours \n`;
  updatedDescription += `Number of crew members needed: ${
    noOfCrew.result ? noOfCrew.result.toString() : noOfCrew.toString()
  }. \n`;
  updatedDescription += `Duration of maintenance / man hours: ${(
    (eachItemDuration.result ? eachItemDuration.result : eachItemDuration) *
    (noOfCrew.result ? noOfCrew.result : noOfCrew) *
    (noOfItems.result ? noOfItems.result : noOfItems)
  ).toString()} hours \n`;
  updatedDescription += `Roles needed: \n`;
  updatedDescription += pc ? `   Control specialist: ${pc.toString()}. \n` : '';
  updatedDescription += pb ? `   Breaker specialist: ${pb.toString()}. \n` : '';
  updatedDescription += px ? `   Transfomer specialist: ${px.toString()}. \n` : '';
  updatedDescription += pv ? `   Valve specialist: ${pv.toString()}. \n` : '';
  updatedDescription += pm ? `   Mechanic: ${pm.toString()}. \n` : '';
  updatedDescription += pe ? `   Electrician: ${pe.toString()}. \n` : '';
  updatedDescription += pex ? `   External Personel: ${pex.toString()}. \n` : '';
  updatedDescription += se ? `   Service Engineer: ${se.toString()}. \n` : '';
  updatedDescription += pg ? `   Helper: ${pg.toString()}. \n` : '';
  updatedDescription += '\n';
  updatedDescription += avarageManHourPerYear
    ? `Avarage man hour/year: ${avarageManHourPerYear.result ? avarageManHourPerYear.result.toString() : avarageManHourPerYear.toString()}. \n`
    : '';
  updatedDescription += noOfMaintenananceDuringLifetime
    ? `Number of maintenance during lifetime: ${noOfMaintenananceDuringLifetime.result ? noOfMaintenananceDuringLifetime.result.toString() : noOfMaintenananceDuringLifetime.toString()}. \n`
    : '';

  return updatedDescription;
};

const addMissingDataToMaintenanceSeries = (
  maintenanceSeries,
  siteComponents,
  startDate,
  endDate,
  timezone
) => {
  let allComponentsFound = true;

  const maintenanceSeriesWithCorrectData = maintenanceSeries.eventSeries.map((series) => {
    const itemDesignationIds = [];
    series.itemDesignations.forEach((itemDesignation) => {
      const foundComp = siteComponents.find((c) => c.itemDesignation === itemDesignation);

      if (foundComp) {
        itemDesignationIds.push(foundComp.id);
      } else {
        allComponentsFound = false;
      }
    });

    let occurs = '';
    let interval;
    // Weeks
    if (series.periodicity > 0 && series.periodicity < 0.0833) {
      occurs = 'WEEKLY';
      interval = Math.round(series.periodicity * 52);
    }
    // Months
    else if (series.periodicity >= 0.0833 && series.periodicity < 1) {
      occurs = 'MONTHLY';
      interval = Math.round(series.periodicity * 12);
    }
    // Years
    else {
      occurs = 'YEARLY';
      interval = Math.round(series.periodicity);
    }

    // we need to include tz info and adjust dtstart to site time
    const siteStart = utcToSite(startDate || Date.now(), timezone);
    const time = `${pad(siteStart.getHours(), 2)}${pad(siteStart.getMinutes(), 2)}${pad(siteStart.getSeconds(), 2)}`;
    let recurrenceRule = `DTSTART;TZID=${timezone}:${siteStart.getFullYear()}${pad(
      siteStart.getMonth() + 1,
      2
    )}${pad(siteStart.getDate(), 2)}T${time}\nRRULE:INTERVAL=${interval};FREQ=${occurs}`;

    if (endDate) {
      const siteEnd = utcToSite(endDate, timezone);
      const timeEnd = `${pad(siteEnd.getHours(), 2)}${pad(siteEnd.getMinutes(), 2)}${pad(siteEnd.getSeconds(), 2)}`;
      recurrenceRule += `;UNTIL=${siteEnd.getFullYear()}${pad(
        siteEnd.getMonth() + 1,
        2
      )}${pad(siteEnd.getDate(), 2)}T${timeEnd}`;
    }

    // replacing components itemDesignations with the ids of the components
    return {
      ...series,
      components: itemDesignationIds,
      recurrenceRule,
      from: startDate || Date.now(),
      to: startDate || Date.now(),
      notificationDate: startDate,
    };
  });

  return [
    allComponentsFound,
    { ...maintenanceSeries, eventSeries: maintenanceSeriesWithCorrectData },
  ];
};

const normalizeItemDesignation = (itemDesignation) => {
  const isValid = isItemDesignation(itemDesignation);

  if (!itemDesignation || !isValid || !itemDesignation.includes('.')) {
    return itemDesignation;
  }

  const validChars = ['=', '+', '-'];
  const replacedString = itemDesignation.replace(/\./g, (match, offset, string) => {
    for (let i = offset - 1; i >= 0; i -= 1) {
      if (validChars.includes(string[i])) {
        return string[i];
      }
    }
    return match;
  });

  const segmentPattern = /([+=-][^+=-]*)/g;
  const matchedSegments = replacedString.match(segmentPattern);
  return matchedSegments ? matchedSegments.join('') : replacedString;
};

function cleanItemDesignationString(itemDesignationString) {
  // remove line breaks
  let newItemDesignationString = itemDesignationString.replace(/(\r\n|\n|\r)/gm, '');
  // remove spaces
  newItemDesignationString = newItemDesignationString.replace(/ /g, '');

  return newItemDesignationString;
}

function filterComponenstsUnique(components) {
  // only keep unique & remove components that already exist on site
  return components.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.itemDesignation === value.itemDesignation)
  );
}

function countItemDesignations(components) {
  let count = 0;
  components.forEach((comp) => {
    if (isItemDesignation(comp.itemDesignation)) count += 1;
  });
  return count;
}

function createMappingsFromComponents(components) {
  // Will convert components with dbids to adapt to mapping format required by endpoint
  return components.map((comp) => {
    return {
      itemDesignations: [comp.itemDesignation],
      dbIds: comp.dbIds,
      geometryIds: [],
      position: { x: 0, y: 0, z: 0 },
    };
  });
}

function addAttributesToComponents(components) {
  // Will convert simple properties on components to adapt to attribute format required by endpoint
  return components.map((comp) => {
    let attributes = [];
    if (comp.properties) {
      attributes = comp.properties.map((prop) => {
        let dataType = 'string';
        let { value } = prop;
        if (typeof value === 'number') {
          dataType = 'number';
        } else if (typeof value === 'boolean') {
          dataType = 'boolean';
        } else if (/^\d+$/.test(value)) {
          dataType = 'number';
          value = Number(value);
        } else if (value.toLowerCase() === 'true') {
          dataType = 'boolean';
          value = true;
        } else if (value.toLowerCase() === 'false') {
          dataType = 'boolean';
          value = false;
        }

        const attr = {
          name: prop.name,
          dataType,
          values: [{ value, timestamp: new Date(), quality: 1 }],
          itemDesignation: comp.itemDesignation,
          category: prop.category,
        };
        return attr;
      });
    }

    return { ...comp, attributes };
  });
}

function createAllLevelsOfComponents(components, existingComponents, rootComponent) {
  const newComponents = [];
  // Create all components including parents
  components.forEach((comp) => {
    if (isItemDesignation(comp.itemDesignation)) {
      const levelArray = comp.itemDesignation.split(/([+=-][^+=-]*)/g).filter((l) => l !== '');

      // Go through each level and create components
      levelArray.forEach((c, index) => {
        const prefix = c.charAt(0);
        let parentItemDesignation;
        let itemDesignation;
        if (index === 0) {
          parentItemDesignation = rootComponent.itemDesignation;
          itemDesignation = c;
        } else {
          parentItemDesignation = levelArray.slice(0, index).join('');
          itemDesignation = parentItemDesignation + c;
        }

        // As root item designation is validated as an item designation it is added to the complete item designation
        // Add all properties to the component to the last level
        if (index + 1 === levelArray.length) {
          newComponents.push({
            ...comp,
            itemDesignation,
            referenceDesignation: itemDesignation,
            name: comp.itemDesignation === itemDesignation && comp.name ? comp.name : c,
            parentItemDesignation,
            type: prefix === '=' ? 'group' : 'component',
          });
        } else {
          newComponents.push({
            itemDesignation,
            referenceDesignation: itemDesignation,
            name: comp.itemDesignation === itemDesignation && comp.name ? comp.name : c,
            parentItemDesignation,
            type: prefix === '=' ? 'group' : 'component',
          });
        }
      });
    } else {
      // If not an item designation just add it to the root as is
      newComponents.push({
        itemDesignation: comp.itemDesignation,
        referenceDesignation: comp.itemDesignation,
        name: comp.itemDesignation,
        parentItemDesignation: rootComponent.itemDesignation,
        type: 'component',
      });
    }
  });

  // Only keep unique & remove components that already exist on site
  return newComponents.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.itemDesignation === value.itemDesignation) &&
      !existingComponents.some((c) => c.itemDesignation === value.itemDesignation)
  );
}

// Recursive resolve parenthesis in item designation
const resolveParenthesisItemDesignation = (itemDesignation) => {
  let itemDesignations = [];
  let errors = [];

  const indexOfLeftParanthesis = itemDesignation.indexOf('(');
  if (indexOfLeftParanthesis >= 0) {
    const indexOfRightParanthesis = itemDesignation.indexOf(')', indexOfLeftParanthesis + 1);
    if (indexOfRightParanthesis > 0) {
      const withinParenthesis = itemDesignation.substring(
        indexOfLeftParanthesis + 1,
        indexOfRightParanthesis
      );

      if (withinParenthesis.includes('(')) {
        errors.push(
          `A parenthesis within a parenthesis was found for reference designation: ${itemDesignation}. This is not allowed.`
        );
      } else {
        // Create array of substrings with comma as separator
        const itemDesignationLevel = withinParenthesis.split(',');
        if (itemDesignationLevel.length === 1) {
          errors.push(
            `At least one of the parenthesis does not have a comma for reference designation: ${itemDesignation}`
          );
        } else if (itemDesignationLevel.length > 1) {
          itemDesignationLevel.forEach((level) => {
            // Return variations of the item designation and call this function again to resolve if there are more paranthesis
            // building all the different variations recursively
            const [items, err] = resolveParenthesisItemDesignation(
              itemDesignation.substring(0, indexOfLeftParanthesis) +
                level +
                itemDesignation.substring(indexOfRightParanthesis + 1)
            );

            itemDesignations = [...itemDesignations, ...items];
            errors = [...errors, ...err];
          });
        } else {
          errors.push(
            `At least one of the parenthesis is empty for reference designation: ${itemDesignation}`
          );
        }
      }
    } else {
      errors.push(
        `There is no right parenthesis in reference designation matching left parenthesis for: ${itemDesignation}`
      );
    }
  } else {
    itemDesignations = [normalizeItemDesignation(cleanItemDesignationString(itemDesignation))];
  }

  return [itemDesignations, errors];
};

const checkUnique = (array, key, errors, message, worksheetName) => {
  const uniqueVal = {};

  array.some((item, index) => {
    const property = item[key];
    // eslint-disable-next-line no-prototype-builtins
    if (uniqueVal.hasOwnProperty(property)) {
      // Index + 2 since the first row in the excel sheet is the header and index starts at 0.
      errors.push(`${worksheetName}: Row ${index + 2}: ${message}`);
      return true;
    }
    if (property) {
      uniqueVal[property] = true;
    }
    return false;
  });
};

const cleanSheet = (sheet) => {
  const validRows = [];

  sheet.eachRow({ includeEmpty: false }, (row) => {
    // strips out all rows that are empty, we can potentially have a couple of thousands of empty rows.
    const rowData = row.values;
    const isValidRow = !rowData.every((value) => value === null || value === '');

    if (isValidRow) {
      rowData.shift();
      validRows.push(rowData);
    }
  });

  return validRows;
};

const validateSheetHeaders = (headers, sheet, map, errorArray) => {
  const columnNames = Object.keys(map);

  headers.forEach((r, i) => {
    const rowName = r.replace(/\*/g, '').trim();
    const columnLetter = sheet.getColumn(i + 1).letter;

    if (!columnNames.includes(rowName) || (columnNames[i] && map[rowName] !== i)) {
      errorArray.push(`Column ${columnNames[i]} should be on column ${columnLetter}`);
    }
  });
};

const parseComponentSheet = async ({ workbook }) => {
  const componentSheet = workbook.worksheets.find((s) => s.name === 'Components');
  const parsedComponents = [];
  let validItemDesignations;
  const errors = [];

  if (!componentSheet) {
    return [[], [], ['No Components sheet found']];
  }

  const COMPONENT_SHEET_MAP = {
    'Reference Designation': 0,
    'Parent Reference Designation': 1,
    'Component Name': 2,
    'Component Type': 3,
    'Custom Id': 4,
  };

  const [sheetHeaders, ...validRows] = cleanSheet(componentSheet);

  validateSheetHeaders(sheetHeaders, componentSheet, COMPONENT_SHEET_MAP, errors);

  checkUnique(
    validRows,
    COMPONENT_SHEET_MAP['Reference Designation'],
    errors,
    'Reference Designation has to be unique.',
    'Components sheet'
  );

  if (!errors.length) {
    validItemDesignations = new Set(
      validRows.map((row) =>
        row[COMPONENT_SHEET_MAP['Reference Designation']]
          ? normalizeItemDesignation(row[COMPONENT_SHEET_MAP['Reference Designation']])
          : row[COMPONENT_SHEET_MAP['Reference Designation']]
      )
    );

    let rowValid = true;

    validRows.forEach((row, index) => {
      const referenceDesignation = row[COMPONENT_SHEET_MAP['Reference Designation']]
        ? normalizeItemDesignation(row[COMPONENT_SHEET_MAP['Reference Designation']])
        : row[COMPONENT_SHEET_MAP['Reference Designation']];
      const parentReferenceDesignation = row[COMPONENT_SHEET_MAP['Parent Reference Designation']]
        ? normalizeItemDesignation(row[COMPONENT_SHEET_MAP['Parent Reference Designation']])
        : null;
      const name = row[COMPONENT_SHEET_MAP['Component Name']];
      const type = row[COMPONENT_SHEET_MAP['Component Type']];
      const customId = row[COMPONENT_SHEET_MAP['Custom Id']];

      if (!referenceDesignation) {
        rowValid = false;
        errors.push(`Components sheet: Reference designation on row ${index + 2} is required.`);
      }

      if (index >= 1 && !parentReferenceDesignation && type !== 'site') {
        rowValid = false;
        errors.push(`Components sheet: Parent on row ${index + 2} is required. `);
      }

      if (index > 1 && !validItemDesignations.has(parentReferenceDesignation)) {
        rowValid = false;
        errors.push(
          `Components sheet: The reference designation set for this parent on row ${index + 2} is invalid.`
        );
      }

      if (index > 1 && parentReferenceDesignation === referenceDesignation) {
        rowValid = false;
        errors.push(
          `Components sheet: The reference designation and parent reference designation on row ${index + 2} cannot be the same.`
        );
      }

      if (!name) {
        rowValid = false;
        errors.push(`Components sheet: Component name on row ${index + 2} is required.`);
      }

      if (!type) {
        rowValid = false;
        errors.push(`Components sheet: Component type on row ${index + 2} is required.`);
      }

      // Check that parent does not exist after in the list to avoid circualar dependencies when updating components
      if (
        parentReferenceDesignation != null &&
        validRows
          .slice(index + 1)
          .some(
            (vRow) =>
              parentReferenceDesignation === vRow[COMPONENT_SHEET_MAP['Reference Designation']]
          )
      ) {
        rowValid = false;
        errors.push(
          `Components sheet: Component type on row ${index + 2} cannot have parent after in the list.`
        );
      }

      const getCompType = (componentType) => {
        if (componentType === 'equipment') {
          return 'component';
        }
        if (componentType === 'area') {
          return 'virtual';
        }
        return componentType;
      };

      if (rowValid) {
        const parsedComponent = {
          itemDesignation: referenceDesignation,
          referenceDesignation,
          parentItemDesignation: parentReferenceDesignation,
          name,
          type: getCompType(type),
          customId: customId ? String(customId) : null,
        };
        parsedComponents.push(parsedComponent);
      }
    });
  }

  return [parsedComponents, validItemDesignations, errors];
};

const parseDocumentSheet = async ({ workbook, drawings, itemDesignations }) => {
  const documentSheet = workbook.worksheets.find((s) => s.name === 'Documents');
  const errors = [];
  const parsedDocuments = [];

  if (!documentSheet) {
    return [[], ['No Document sheet found']];
  }

  const DOCUMENT_SHEET_MAP = {
    Document: 0,
    'Reference Designation': 1,
    Width: 2,
    Height: 3,
  };

  // Removing the headers on the first row in the excel
  const [sheetHeaders, ...validRows] = cleanSheet(documentSheet);
  validateSheetHeaders(sheetHeaders, documentSheet, DOCUMENT_SHEET_MAP, errors);

  checkUnique(
    validRows,
    DOCUMENT_SHEET_MAP.Document,
    errors,
    'Document has to be unique.',
    'Documents sheet'
  );

  if (!errors.length) {
    let rowValid = true;

    validRows.forEach((row, index) => {
      const document = row[DOCUMENT_SHEET_MAP.Document];
      const referenceDesignation = row[DOCUMENT_SHEET_MAP['Reference Designation']]
        ? normalizeItemDesignation(row[DOCUMENT_SHEET_MAP['Reference Designation']])
        : row[DOCUMENT_SHEET_MAP['Reference Designation']];
      const height = row[DOCUMENT_SHEET_MAP.Height];
      const width = row[DOCUMENT_SHEET_MAP.Width];

      const drawingsMatched = drawings.filter((drawing) => drawing.originalName === document);

      if (drawingsMatched.length === 0) {
        rowValid = false;
        errors.push(
          `Documents Sheet: This document on row ${index + 2} does not exist on the site.`
        );
      }

      if (drawingsMatched.length > 1) {
        rowValid = false;
        errors.push(
          `Documents Sheet: There are several documents with the same document name that exist for row ${index + 2}. There can only be one, so make sure to remove the others and try again`
        );
      }

      if (!itemDesignations.has(referenceDesignation)) {
        rowValid = false;
        errors.push(
          `Documents Sheet: Reference designation on row ${index + 2} is was not included in the components sheet.`
        );
      }

      if (!referenceDesignation) {
        rowValid = false;
        errors.push(`Documents Sheet: Reference designation on row ${index + 2} is required.`);
      }

      if (!height) {
        rowValid = false;
        errors.push(`Documents Sheet: Height on row ${index + 2} is required.`);
      }

      if (!width) {
        rowValid = false;
        errors.push(`Documents Sheet: Width on row ${index + 2} is required.`);
      }

      if (rowValid) {
        const parsedDocument = {
          name: document,
          versionId: drawingsMatched[0].versionId,
          modelId: drawingsMatched[0].modelId,
          displayName: drawingsMatched[0].displayName,
          itemDesignation: normalizeItemDesignation(referenceDesignation),
          height: Number(height),
          width: Number(width),
        };
        parsedDocuments.push(parsedDocument);
      }
    });
  }
  return [parsedDocuments, errors];
};

// Waiting for more info about this from Ben & Balaji about the structure.
const parseAttributesSheet = async ({ workbook, parsedComponents }) => {
  const parsedAttributes = {};
  const errors = [];

  const attributesSheet = workbook.worksheets.find((s) => s.name === 'Attributes');

  if (!attributesSheet) {
    return [[], ['No Attributes sheet found']];
  }

  const ATTRIBUTES_SHEET_MAP = {
    'Reference Designation': 0,
    'Attribute Name': 1,
    Value: 2,
    'Data type': 3,
    Category: 4,
    Unit: 5,
  };

  const [sheetHeaders, ...validRows] = cleanSheet(attributesSheet);
  validateSheetHeaders(sheetHeaders, attributesSheet, ATTRIBUTES_SHEET_MAP, errors);

  if (!errors.length) {
    let rowValid = true;

    validRows.forEach((row, index) => {
      const referenceDesignation = row[ATTRIBUTES_SHEET_MAP['Reference Designation']]
        ? normalizeItemDesignation(row[ATTRIBUTES_SHEET_MAP['Reference Designation']])
        : row[ATTRIBUTES_SHEET_MAP['Reference Designation']];
      const attributeName = row[ATTRIBUTES_SHEET_MAP['Attribute Name']];

      const value = row[ATTRIBUTES_SHEET_MAP.Value];
      const dataType = row[ATTRIBUTES_SHEET_MAP['Data type']];
      const category = row[ATTRIBUTES_SHEET_MAP.Category];
      const unit = row[ATTRIBUTES_SHEET_MAP.Unit];

      if (!referenceDesignation) {
        rowValid = false;
        errors.push(`Attributes sheet: Reference designation on row ${index + 2} is required.`);
      }

      if (!attributeName) {
        rowValid = false;
        errors.push(`Attributes sheet: Attribute name on row ${index + 2} is required.`);
      }

      if (value == null) {
        rowValid = false;
        errors.push(`Attributes sheet: Value on row ${index + 2} is required.`);
      }

      if (
        !dataType &&
        !(dataType === 'string' || dataType === 'boolean' || dataType === 'number')
      ) {
        rowValid = false;
        errors.push(
          `Attributes sheet: Data type on row ${index + 2} is required and needs to be one of string, boolean or number.`
        );
      }

      if (!parsedComponents.map((c) => c.itemDesignation).includes(referenceDesignation)) {
        rowValid = false;
        errors.push(
          `Attributes sheet: (Row ${index + 2}) ${referenceDesignation ? `No component with reference designation ${referenceDesignation}.` : 'Reference designation is empty.'}`
        );
      }

      if (rowValid) {
        const parsedAttribute = {
          name: attributeName,
          dataType,
          values: [{ value, timestamp: new Date(), quality: 1 }],
          itemDesignation: referenceDesignation,
          category: category ? String(category) : null,
          ...(unit && unit !== '' && { unit }),
        };

        parsedAttributes[referenceDesignation] = [
          ...(parsedAttributes[referenceDesignation] || []),
          parsedAttribute,
        ];
      }
    });
  }

  return [
    parsedComponents.map((c) => ({
      ...c,
      attributes: parsedAttributes[c.itemDesignation] || [],
    })),
    errors,
  ];
};

const parseShapesSheet = async ({ workbook, documents, itemDesignations }) => {
  const documentsWithShapes = {};
  const errors = [];

  const shapesSheet = workbook.worksheets.find((s) => s.name === 'Shapes');

  if (!shapesSheet) {
    return [[], ['No Attributes sheet found']];
  }

  const SHAPES_SHEET_MAP = {
    Document: 0,
    'Reference Designation': 1,
    'Shape Name': 2,
    X1: 3,
    Y1: 4,
    X2: 5,
    Y2: 6,
    Width: 7,
    Height: 8,
  };

  const [sheetHeaders, ...validRows] = cleanSheet(shapesSheet);
  validateSheetHeaders(sheetHeaders, shapesSheet, SHAPES_SHEET_MAP, errors);

  if (!errors.length) {
    let rowValid = true;

    validRows.forEach((row, index) => {
      const documentName = row[SHAPES_SHEET_MAP.Document];
      const referenceDesignation = row[SHAPES_SHEET_MAP['Reference Designation']]
        ? normalizeItemDesignation(row[SHAPES_SHEET_MAP['Reference Designation']])
        : row[SHAPES_SHEET_MAP['Reference Designation']];
      const shapeName = row[SHAPES_SHEET_MAP['Shape Name']];
      const x1 = row[SHAPES_SHEET_MAP.X1];
      const y1 = row[SHAPES_SHEET_MAP.Y1];
      const x2 = row[SHAPES_SHEET_MAP.X2];
      const y2 = row[SHAPES_SHEET_MAP.Y2];
      const width = row[SHAPES_SHEET_MAP.Width];
      const height = row[SHAPES_SHEET_MAP.Height];

      if (
        !documentName ||
        !referenceDesignation ||
        !shapeName ||
        !x1 ||
        !y1 ||
        !x2 ||
        !y2 ||
        !width ||
        !height
      ) {
        rowValid = false;
        errors.push(
          `Shapes sheet: At least one column is not entered on row ${index + 2}. All values are required`
        );
      }

      if (!documents.some((d) => d.name === documentName)) {
        rowValid = false;
        errors.push(
          `Shapes sheet: Document on row ${index + 2} is not included in the documents sheet.`
        );
      }

      if (!itemDesignations.has(referenceDesignation)) {
        rowValid = false;
        errors.push(
          `Shapes sheet: Reference designation on row ${index + 2} is was not included in the components sheet.`
        );
      }

      if (rowValid) {
        const document = documents.find((d) => d.name === documentName);

        // The scalefactor transforms the coordinate system of visio to IdentiQ coordinate system
        // The number 250 and 88 comes from that the specific format of the .dwg always creates the drawing in the same size in THREE.js coordinates
        const scaleFactor = 250 / document.width;
        const offsetY = 88;

        // The divided by two etc is because in IdentiQ we use the center of the drawing as origa while Visio use bottom left corner as origo
        const geometry = {
          name: referenceDesignation,
          position: {
            x: x1 * scaleFactor + (width * scaleFactor) / 2,
            y: y1 * scaleFactor + offsetY - (height * scaleFactor) / 2,
            z: 0,
          },
          quaternion: {
            x: 0,
            y: 0,
            z: 0,
            w: 1,
          },
          scale: {
            x: width * scaleFactor,
            y: height * scaleFactor,
            z: 1,
          },
          geometryType: 'PlaneGeometry',
          material: {
            color: '#9898FC',
            opacity: 0.02,
            transparent: true,
          },
          type: 'object',
        };

        documentsWithShapes[documentName] = [
          ...(documentsWithShapes[documentName] || []),
          geometry,
        ];
      }
    });

    const documentsWithGeometries = documents.map((c) => ({
      ...c,
      geometries: documentsWithShapes[c.name] || [],
    }));

    // Sort by size of geometries
    documentsWithGeometries.forEach((doc) => {
      doc.geometries.sort((mappingA, mappingB) => {
        return mappingB.scale.x * mappingB.scale.y - mappingA.scale.x * mappingA.scale.y;
      });

      // Make objects clickable in correct order by changing z in accordance with size
      /* eslint-disable no-param-reassign */
      doc.geometries.forEach((geometry, index) => {
        geometry.position.z = index;
      });
      /* eslint-enable no-param-reassign */
    });

    return [documentsWithGeometries, errors];
  }
  return [[], errors];
};

const parseMaintenancePlanSheet = async ({
  workbook,
  components,
  site,
  org,
  startDate,
  endDate,
  timezone,
}) => {
  const parsedMaintenanceSeries = [];
  let maintenanceComponents = [];
  let missingComponents = [];
  const errors = [];

  const rootComponent = components.find((c) => c.parent == null);
  if (!rootComponent) {
    return [[], [], [], ['No root component on site, please add one and try again']];
  }
  if (!isItemDesignation(rootComponent.itemDesignation)) {
    return [
      [],
      [],
      [],
      ['"Root component requires a valid reference designation. Please update it.'],
    ];
  }

  // Only support single site
  const sites = components.filter((c) => c.type === 'site');
  if (sites.length > 1) {
    return [[], [], [], ['The import does not support multiple sites']];
  }

  const maintenanceSheet = workbook.worksheets.find((s) => s.name === 'Maintenance');

  if (!maintenanceSheet) {
    return [[], [], [], ['No Maintenace sheet found']];
  }

  let isHVDC = true;
  // Check if FACTS or HVDC template
  const maintenanceInspectionClass = maintenanceSheet.getRow(1).getCell(4).value;
  if (maintenanceInspectionClass === 'Maintenance Inspection Class') {
    isHVDC = false;
  }

  let rows = [];
  maintenanceSheet.eachRow({}, (row) => {
    const rowData = row.values;
    rowData.shift();
    rows.push(rowData);
  });
  rows = rows.slice(isHVDC ? 6 : 1);

  const MAINTENANCE_SHEET_MAP = isHVDC
    ? {
        itemDesignations: 0,
        itemName: 1,
        inspectionIdentifier: 2,
        noOfItems: 3,
        tBM: 4,
        noOfMaintenananceDuringLifetime: 5,
        pc: 6,
        pb: 7,
        px: 8,
        pv: 9,
        pm: 10,
        pe: 11,
        pg: 12,
        pex: 13,
        noOfCrew: 14,
        eachItemDuration: 15,
        avarageManHourPerYear: 16,
        energized: 17,
        operation: 18,
        description: 19,
      }
    : {
        itemDesignations: 0,
        itemName: 1,
        responsibility: 2,
        inspectionIdentifier: 3,
        noOfItems: 4,
        tBM: 5,
        pc: 6,
        pb: 7,
        px: 8,
        se: 9,
        pm: 10,
        pe: 11,
        pg: 12,
        noOfCrew: 13,
        eachItemDuration: 14,
        avarageManHourPerYear: 15,
        energized: 17,
        description: 18,
      };
  if (!errors.length) {
    rows.forEach((row, index) => {
      let rowValid = true;
      const itemDesignations = row[MAINTENANCE_SHEET_MAP.itemDesignations];
      const itemName = row[MAINTENANCE_SHEET_MAP.itemName];

      const inspectionIdentifier = row[MAINTENANCE_SHEET_MAP.inspectionIdentifier];
      const noOfItems = row[MAINTENANCE_SHEET_MAP.noOfItems];
      const tBM = row[MAINTENANCE_SHEET_MAP.tBM];
      const pc = row[MAINTENANCE_SHEET_MAP.pc];
      const pb = row[MAINTENANCE_SHEET_MAP.pb];
      const px = row[MAINTENANCE_SHEET_MAP.px];
      const pm = row[MAINTENANCE_SHEET_MAP.pm];
      const pe = row[MAINTENANCE_SHEET_MAP.pe];
      const pg = row[MAINTENANCE_SHEET_MAP.pg];
      const noOfCrew = row[MAINTENANCE_SHEET_MAP.noOfCrew];
      const eachItemDuration = row[MAINTENANCE_SHEET_MAP.eachItemDuration];
      const avarageManHourPerYear = row[MAINTENANCE_SHEET_MAP.avarageManHourPerYear];
      let description = row[MAINTENANCE_SHEET_MAP.description];
      const energized = row[MAINTENANCE_SHEET_MAP.energized];

      // HVDC specific
      const pv = isHVDC ? row[MAINTENANCE_SHEET_MAP.pv] : null;
      const pex = isHVDC ? row[MAINTENANCE_SHEET_MAP.pex] : null;
      const noOfMaintenananceDuringLifetime = isHVDC
        ? row[MAINTENANCE_SHEET_MAP.noOfMaintenananceDuringLifetime]
        : null;
      const operation = isHVDC ? row[MAINTENANCE_SHEET_MAP.operation] : null;

      // FACTS specific
      const responsibility = !isHVDC ? row[MAINTENANCE_SHEET_MAP.responsibility] : null;
      const se = !isHVDC ? row[MAINTENANCE_SHEET_MAP.se] : null;

      // If no itemDesignation and item name, just skip the row as not valid without throwing error
      if (itemDesignations && itemName) {
        // If item designation and item name, throw error if missing certain columns
        if (
          !inspectionIdentifier ||
          !noOfItems ||
          !tBM ||
          !noOfCrew ||
          !eachItemDuration ||
          !description ||
          !energized
        ) {
          rowValid = false;
          errors.push(
            `Maintenance sheet: At least one column is not entered correctly on row ${index + 7}.`
          );
        }
      } else {
        rowValid = false;
      }

      if (tBM < 0) {
        rowValid = false;
        errors.push(`Maintenance sheet: TBM is negative on row ${index + 7}.`);
      }

      if (rowValid && typeof tBM !== 'number') {
        rowValid = false;
        errors.push(
          `Maintenance sheet: TBM is not a number on row ${index + 7}. Make sure merged columns are either removed or split`
        );
      }

      if (rowValid) {
        const [itemDesginationsArray, paranthesisErrors] =
          resolveParenthesisItemDesignation(itemDesignations);

        // Map itemDesignationsArray to itemName and add root item designation to all except the one's matching with root
        const itemDesignationsWithNames = itemDesginationsArray.map((item) => {
          return {
            name: itemName,
            itemDesignation:
              isItemDesignation(item) && item !== rootComponent.itemDesignation
                ? rootComponent.itemDesignation + item
                : item,
          };
        });
        maintenanceComponents = [...maintenanceComponents, ...itemDesignationsWithNames];

        if (paranthesisErrors.length === 0) {
          description = buildMaintenanceMessageString({
            description,
            energized,
            operation,
            responsibility,
            noOfItems,
            eachItemDuration,
            noOfCrew,
            pc,
            pb,
            px,
            pv,
            pm,
            pe,
            pex,
            se,
            pg,
            avarageManHourPerYear,
            noOfMaintenananceDuringLifetime,
          });

          // Create Maintenance series without component id and dates
          const maintenanceSeries = {
            type: 'maintenance',
            planned: true,
            state: 'pending',
            assignedTo: 'NA',
            name: `Preventative maintenance: ${itemName} ${isHVDC ? 'RS NO' : 'Class'}: ${inspectionIdentifier}`,
            description,
            itemDesignations: itemDesignationsWithNames.map((item) => item.itemDesignation),
            periodicity: tBM,
            notifyAt: '1_week',
            executionFrom: null,
            executionTo: null,
            attributes: {
              maintenanceType: 'Maintenance Type 1',
            },
            task: true,
            site,
            org,
            tags: [],
            files: [],
            events: {},
          };
          parsedMaintenanceSeries.push(maintenanceSeries);
        } else {
          errors.push(...paranthesisErrors);
        }
      }
    });

    // Only keep unique
    maintenanceComponents = filterComponenstsUnique(maintenanceComponents);

    // Filter away components that already exist to not create all levels without needing to
    missingComponents = maintenanceComponents.filter((mc) => {
      return !components.some((c) => c.itemDesignation === mc.itemDesignation);
    });
    maintenanceComponents = createAllLevelsOfComponents(
      missingComponents,
      components,
      rootComponent
    );
  }

  let maintenanceSeriesData = {
    site,
    org,
    overwriteTag: 'HE MAL',
    eventSeries: parsedMaintenanceSeries,
  };

  // If startDate is not present, it will be added later once the startDate has been entered by the user
  if (startDate) {
    maintenanceSeriesData = addMissingDataToMaintenanceSeries(
      maintenanceSeriesData,
      components,
      startDate,
      endDate || null,
      timezone
    );
  }

  return [maintenanceSeriesData, maintenanceComponents, missingComponents, errors];
};

const parseMaintenanceExcel = async ({ file, components, site, org }) => {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);

  const [maintenanceSeries, maintenanceComponents, missingComponents, maintenanceErrors] =
    await parseMaintenancePlanSheet({
      workbook,
      components,
      site,
      org,
    });

  return [maintenanceSeries, maintenanceComponents, missingComponents, maintenanceErrors];
};

const parseAutomationExcel = async ({ file, drawings }) => {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);

  const documentsSheet = workbook.worksheets.find((s) => s.name === 'Documents');

  try {
    const [parsedComponents, itemDesignations, componentErrors] = await parseComponentSheet({
      workbook,
    });
    if (componentErrors.length > 0) {
      throw new ParseExcelError(componentErrors);
    }

    const [parsedComponentsWithAttributes, attributesErrors] = await parseAttributesSheet({
      workbook,
      parsedComponents,
    });

    if (attributesErrors.length > 0) {
      throw new ParseExcelError(attributesErrors);
    }

    // Only try parse documents and shapes if there is a documents sheet
    if (documentsSheet) {
      const [documents, documentErrors] = await parseDocumentSheet({
        workbook,
        drawings,
        itemDesignations,
      });

      if (documentErrors.length > 0) {
        throw new ParseExcelError(documentErrors);
      }

      const [documentsWithShapes, shapesErrors] = await parseShapesSheet({
        workbook,
        documents,
        itemDesignations,
      });

      if (shapesErrors.length > 0) {
        throw new ParseExcelError(shapesErrors);
      }

      return [parsedComponentsWithAttributes, documentsWithShapes, []];
    }
    return [parsedComponentsWithAttributes, [], []];
  } catch (err) {
    return [
      [],
      [],
      err instanceof ParseExcelError ? err.errors : ['An error occured parsing the excel'],
    ];
  }
};

export {
  parseComponentSheet,
  parseDocumentSheet,
  parseShapesSheet,
  parseAttributesSheet,
  parseAutomationExcel,
  parseMaintenancePlanSheet,
  parseMaintenanceExcel,
  addMissingDataToMaintenanceSeries,
  filterComponenstsUnique,
  createAllLevelsOfComponents,
  normalizeItemDesignation,
  countItemDesignations,
  addAttributesToComponents,
  createMappingsFromComponents,
};
