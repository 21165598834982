import React, { useState, useCallback, useEffect } from 'react';
import { Icon } from '@iq/react-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

import './BimExtractModal.scss';
import JSONEditor from '../../../../../JSONEditor';
import SimpleModal from '../../../../../SimpleModal';

import { getExtractImportSchema, getExtractImportUiSchema } from './BimExtractSchema';

const ImportMaintenancePlanModal = ({
  onClose,
  onSubmit,
  onFormUpdate,
  bimComponents,
  componentsAllLevels,
  missingComponents,
  propertyNames,
  categoryNames,
  itemDesignationsCount,
  bimExtractConfig,
  importingBimData,
}) => {
  const [message, setMessage] = useState();
  const [formData, setFormData] = useState({});
  const iconContainer = (
    <div className="message--icon">
      <Icon
        icon="he-info"
        size="s"
      />
    </div>
  );
  const defaultItemDesignation = propertyNames.some(
    (p) => bimExtractConfig.itemDesignationName === p
  )
    ? bimExtractConfig.itemDesignationName
    : '';
  const defaultCategory = categoryNames.some((c) =>
    bimExtractConfig.readCategories.some((rc) => c === rc)
  )
    ? bimExtractConfig.readCategories
    : [];
  const showUnmachedComponentsUi = itemDesignationsCount !== 0;

  const onFormSubmit = useCallback(async () => {
    onSubmit(formData.createUnmatchedComponents, bimComponents, componentsAllLevels);
  }, [formData, bimComponents, componentsAllLevels]);

  const showMessage = (bimImportInfo) => {
    setMessage(
      <div className="message--container">
        {iconContainer}
        <div className="message--message">
          <div>{`${bimImportInfo[0]}`}</div>
          {bimImportInfo.slice(1).map((messagePart, key) => {
            return <div key={key}>{messagePart}</div>;
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    let bimImportInfo = [];

    const validDesignationsText =
      bimComponents.length - itemDesignationsCount !== 0
        ? `There are ${itemDesignationsCount} valid and ${bimComponents.length - itemDesignationsCount} not valid reference designations in the model.`
        : `All reference designations are valid in the model.`;
    if (itemDesignationsCount === 0) {
      bimImportInfo = [
        `There are no valid reference designations for this reference designation attribute in the model. Please select the correct attribute`,
      ];
    } else if (formData.createUnmatchedComponents) {
      bimImportInfo = [
        validDesignationsText,
        `${missingComponents.length} components and ${bimComponents.length} component mappings will be created/updated on import`,
      ];
    } else if (bimComponents.length - missingComponents.length === 0) {
      bimImportInfo = [
        `There are no mappings that can be created without adding missing components. Please check the box above to add them`,
      ];
    } else {
      const filteredComponents = bimComponents.filter((mc) => {
        return !missingComponents.some((c) => c.itemDesignation === mc.itemDesignation);
      });
      bimImportInfo = [
        validDesignationsText,
        `${filteredComponents.length} component mappings will be created/updated on import`,
      ];
    }
    showMessage(bimImportInfo);
  }, [itemDesignationsCount, missingComponents, bimComponents]);

  const onHandleChange = useCallback(
    ({ formData: updatedFormData }) => {
      if (updatedFormData.itemDesignationName && updatedFormData.readCategories) {
        setFormData((fd) => ({ ...fd, ...updatedFormData }));
        onFormUpdate({
          itemDesignationName: updatedFormData.itemDesignationName,
          readCategories: updatedFormData.readCategories,
          categoryName: bimExtractConfig.categoryName,
        });
      }
    },
    [formData]
  );

  const onCancelImport = () => {
    onClose();
  };

  return (
    <>
      <SimpleModal
        className="bim-extract-modal"
        onClose={onClose}
        title="Import BIM data [Beta]"
      >
        <div>
          <div className="json-editor">
            <JSONEditor
              formData={formData}
              schema={getExtractImportSchema(
                missingComponents.length,
                formData.createUnmatchedComponents,
                propertyNames,
                categoryNames,
                defaultItemDesignation,
                defaultCategory,
                showUnmachedComponentsUi
              )}
              uiSchema={getExtractImportUiSchema()}
              editorOnly
              cancelCallback={onCancelImport}
              onFormChange={onHandleChange}
              onFormSubmit={onFormSubmit}
              saveButtonText={'Import BIM data'}
              initialEditMode={true}
              showEditButton={false}
              customMessage={message}
              submitDisabled={
                itemDesignationsCount === 0 ||
                (bimComponents.length - missingComponents.length === 0 &&
                  !formData.createUnmatchedComponents) ||
                importingBimData
              }
            />
          </div>
          <div></div>
        </div>
      </SimpleModal>
    </>
  );
};

ImportMaintenancePlanModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormUpdate: PropTypes.func.isRequired,
  componentsAllLevels: PropTypes.array.isRequired,
  bimComponents: PropTypes.array.isRequired,
  missingComponents: PropTypes.array.isRequired,
  bimExtractConfig: PropTypes.object.isRequired,
  itemDesignationsCount: PropTypes.number.isRequired,
};

export default ImportMaintenancePlanModal;
