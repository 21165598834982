/* eslint-disable no-param-reassign */

const getExtractImportSchema = (
  missingComponents,
  checked,
  propertyNames,
  categoryNames,
  defaultPropertyName,
  defaultCategories,
  showUnmachedComponents
) => {
  const schema = {
    type: 'object',
    properties: {
      itemDesignationName: {
        type: 'string',
        enum: propertyNames,
        title: 'Reference designation attribute in model',
        default: defaultPropertyName,
      },
      readCategories: {
        isMulti: true,
        type: 'array',
        title: 'Categories to import',
        uniqueItems: true,
        default: defaultCategories,
        items: {
          type: 'string',
          anyOf: categoryNames.map((cat) => ({
            enum: [cat],
            title: cat,
          })),
        },
      },

      ...(missingComponents > 0 &&
        showUnmachedComponents && {
          createUnmatchedComponents: {
            type: 'boolean',
            default: false,
            title: 'Create missing components',
            description: !checked
              ? `${missingComponents} of the components in the model do not exist in IdentiQ.\n Enable creation by checking the box below.`
              : '',
          },
        }),
    },
  };

  return schema;
};

const getExtractImportUiSchema = () => {
  return {
    'ui:options': {
      orderable: false,
    },
    readCategories: { 'ui:classNames': 'multi-select-array' },
  };
};

export { getExtractImportSchema, getExtractImportUiSchema };
