import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Icon, useTheme, Button } from '@iq/react-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  ModelViewer,
  Model,
  Sidebar,
  ComponentMapping,
  GeometriesEditor,
  Geometries,
  SceneConfig,
  Screenshots,
  THEME_AVT_DARK,
  THEME_AVT_BRIGHT,
  GEOMETRIES_EDITOR_EXTENSION_NAME,
  COMPONENT_MAPPING_EXTENSION_NAME,
  SCENE_CONFIG_EXTENSION_NAME,
} from '@iq/model-viewer';

// eslint-disable-next-line import/no-unresolved
import '@iq/model-viewer/dist/index.css';

import {
  createAllLevelsOfComponents,
  filterComponenstsUnique,
  normalizeItemDesignation,
  countItemDesignations,
  addAttributesToComponents,
  createMappingsFromComponents,
} from '../../../../../externalUtils';
import BigModal from '../../../../BigModal';
import SimpleModal from '../../../../SimpleModal';
import CustomSelect from '../../../../CustomSelect';
import ConfirmationDialog from '../../../../ConfirmationDialog';
import BimExtractModal from './BimExtractModal/BimExtractModal';

import {
  createMapping,
  updateMapping,
  deleteMapping,
  updateOrDeleteMappings,
  getVersionMappings,
  getModelGeometries,
  requestGeometries,
  requestMappings,
  deleteGeometry,
  createOrUpdateGeometry,
  createMappings,
} from '../../../../../bundles/geometry-mappings';
import { updateSceneConfig } from '../../../../../bundles/models';
import {
  getComponentHashmap,
  requestComponents,
  upsertComponents,
} from '../../../../../bundles/components';
import {
  getModelThumbnail,
  requestModelThumbnail,
  getActiveSiteId,
  getActiveSite,
} from '../../../../../bundles/sites';

import { getServiceToken } from '../../../../../services';

const EXTENSION_MAPPING = {
  geometries: GEOMETRIES_EDITOR_EXTENSION_NAME,
  mapping: COMPONENT_MAPPING_EXTENSION_NAME,
  config: SCENE_CONFIG_EXTENSION_NAME,
};

const ModelEditModal = ({
  geometryMapping,
  sceneConfig,
  title = 'Model Explorer',
  model,
  version,
  onCloseModal,
  activeExtension,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const site = useSelector(getActiveSite);
  const siteId = useSelector(getActiveSiteId);
  const hashmap = useSelector(getComponentHashmap);
  const thumbnail = useSelector((state) => getModelThumbnail(state, model.id, version.id));
  const geometries = useSelector((state) => getModelGeometries(state, siteId, model.id));
  const mappings = useSelector((state) => getVersionMappings(state, siteId, model.id, version.id));
  const [components, setComponents] = useState([]);
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [removeMappingScope, setRemoveMappingScope] = useState('all');
  const [busy, setBusy] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [bimExtractConfig, setBimExtractConfig] = useState({
    itemDesignationName: 'ITEM DESIGNATION',
    readCategories: ['Instance Part Properties'],
    categoryName: 'BIM DATA',
  });
  const [bimData, setBimData] = useState({
    bimComponentsToCreate: [],
    missingComponents: [],
    propertyNames: [],
    categoryNames: [],
    itemDesignationsCount: 0,
    doBimExtraction: () => {},
  });
  const [importingBimData, setImportingBimData] = useState(false);

  const [outputs] = version.outputs.filter((o) => o.type === 'svf');
  const urn = outputs && outputs.url;
  const versionConfig = version.sceneConfig;
  const [bimModalOpen, setBimModalOpen] = useState(false);

  useEffect(() => {
    dispatch(requestComponents(siteId));
    dispatch(requestGeometries(siteId, model.id));
    dispatch(requestMappings(siteId));
    dispatch(requestModelThumbnail(model.id, version.id));
  }, []);

  useEffect(() => {
    setComponents(
      Object.values(hashmap).map((c) => ({
        name: c.name,
        itemDesignation: c.itemDesignation,
        type: c.type,
      }))
    );
  }, [hashmap]);

  useEffect(() => {
    setBusy(false);
    setShowMappingModal(false);
  }, [version]);

  const onGetAccessToken = async (callback) => {
    const token = await getServiceToken('model');
    callback(token, 30);
  };

  const handleGeometryMappingUpdate = useCallback((mapping, callback) => {
    if (mapping.id) {
      const { warning, ...update } = mapping;
      dispatch(updateMapping(siteId, model.id, version.id, update, callback));
    } else {
      dispatch(createMapping(siteId, model.id, version.id, mapping, callback));
    }
  }, []);

  const handleSaveGeometries = useCallback(
    (geometryUpdates, callback) => {
      dispatch(createOrUpdateGeometry(siteId, model.id, geometryUpdates, callback));
    },
    [siteId, model]
  );

  const handleClearMappings = useCallback(() => {
    setShowMappingModal(true);
  }, []);

  const handleRemoveMappings = useCallback(() => {
    setBusy(true);
    const onComplete = () => {
      setBusy(false);
      setShowMappingModal(false);
    };
    dispatch(updateOrDeleteMappings(siteId, model.id, version.id, removeMappingScope, onComplete));
  }, [siteId, model, version, removeMappingScope]);

  const handleExtractBIM = useCallback(
    (extractedBimData, doBimExtraction) => {
      // Only keep unique and remove everything after space if exist
      const bimComponents = filterComponenstsUnique(extractedBimData.listOfComponents).map(
        (comp) => {
          return {
            ...comp,
            itemDesignation: normalizeItemDesignation(
              comp.itemDesignation.indexOf(' ') > 0
                ? comp.itemDesignation.substring(0, comp.itemDesignation.indexOf(' '))
                : comp.itemDesignation
            ),
          };
        }
      );

      const rootComponent = components.find((comp) => comp.type === 'site');
      // Filter away components that already exist to not create all levels without needing to
      const missingComponents = bimComponents.filter((mc) => {
        return !components.some((c) => c.itemDesignation === mc.itemDesignation);
      });
      const bimComponentsAllLevels = createAllLevelsOfComponents(
        missingComponents,
        components,
        rootComponent
      );

      const itemDesignationsCount = countItemDesignations(bimComponents);

      setBimData({
        bimComponentsAllLevels,
        missingComponents,
        bimComponents,
        propertyNames: extractedBimData.listOfAttributes,
        categoryNames: extractedBimData.listOfCategories,
        itemDesignationsCount,
        doBimExtraction,
      });
      setBimModalOpen(true);
      setImportingBimData(false);
    },
    [components, bimData]
  );

  const handleDeleteMapping = useCallback(
    (mapping) => {
      if (mapping.id) {
        dispatch(deleteMapping(siteId, model.id, version.id, mapping.id));
      }
    },
    [siteId, model, version]
  );

  useEffect(() => {
    if (confirmed && confirmationDialog) {
      if (confirmationDialog.geometryIdToDelete) {
        dispatch(
          deleteGeometry(
            siteId,
            model.id,
            confirmationDialog.geometryIdToDelete,
            confirmationDialog.callback
          )
        );
      }
      setConfirmationDialog(null);
      setConfirmed(false);
    }
  }, [siteId, model, confirmed, confirmationDialog]);

  const handleClose = useCallback(() => {
    setBimModalOpen(false);
  }, []);

  const onSubmitBimData = useCallback(
    (createUnmatchedComponents, bimComponents, bimComponentsAllLevels) => {
      let bimComps = bimComponents;
      const componentsToCreateOrUpdate = addAttributesToComponents(bimComponentsAllLevels);

      if (createUnmatchedComponents) {
        dispatch(upsertComponents(site.id, site.org, componentsToCreateOrUpdate, true));
      } else {
        // Filter out components that are missing
        bimComps = bimComps.filter((mc) => {
          return !bimData.missingComponents.some((c) => c.itemDesignation === mc.itemDesignation);
        });
      }

      const newMappings = createMappingsFromComponents(bimComps);
      dispatch(createMappings(site.id, model.id, version.id, newMappings));

      setBimModalOpen(false);
    },
    [bimData]
  );

  const onBimConfigChanged = useCallback(
    (newConfig) => {
      setBimExtractConfig(newConfig);
      bimData.doBimExtraction(
        newConfig.itemDesignationName,
        newConfig.readCategories,
        newConfig.categoryName
      );
      setImportingBimData(true);
    },
    [bimExtractConfig, bimData]
  );

  const handleDeleteGeometry = useCallback(
    ({ shouldConfirm = false, ...confirmationOptions }, callback) => {
      if (shouldConfirm) {
        setConfirmationDialog({ ...confirmationOptions, callback });
      } else {
        dispatch(
          deleteGeometry(siteId, model.id, confirmationOptions.geometryIdToDelete, callback)
        );
      }
    },
    [siteId, model]
  );

  const getThumbnail = useCallback(() => thumbnail, [thumbnail]);

  const onUpdateSceneConfig = useCallback(
    (config) => {
      dispatch(updateSceneConfig(siteId, model.id, version.id, config));
    },
    [siteId, model, version]
  );

  const activeSidebarItem = useMemo(() => EXTENSION_MAPPING[activeExtension], [activeExtension]);

  return (
    <>
      <BigModal
        className="model-edit-modal"
        onClose={onCloseModal}
        overlayCanClose={false}
      >
        <div className="top-toolbar">
          <h2>{title}</h2>
          <Icon
            size="xs"
            icon="he-close"
            onClick={onCloseModal}
          />
        </div>

        <div className="model-edit-content">
          {urn ? (
            <ModelViewer
              theme={theme === 'dark' ? THEME_AVT_DARK : THEME_AVT_BRIGHT}
              onGetAccessToken={onGetAccessToken}
              sceneConfig={versionConfig}
              alternateBranding={site?.siteId === 'non-identiq'}
              options={{ reverseMouseZoomDir: true }}
              activeModelId={model.id}
              // showUi={true} uncomment and alter styles to show viewCube
            >
              <Model
                urn={urn}
                avtId={model.id}
              />
              <Sidebar activeId={activeSidebarItem}>
                {geometryMapping && (
                  <ComponentMapping
                    mappings={mappings}
                    geometries={geometries}
                    components={components}
                    canCreate={true}
                    onSaveMapping={handleGeometryMappingUpdate}
                    onClearMappings={handleClearMappings}
                    onDeleteMapping={handleDeleteMapping}
                    onExtractBIM={handleExtractBIM}
                    bimExtractConfig={bimExtractConfig}
                  />
                )}
                {geometryMapping && (
                  <GeometriesEditor
                    mappings={mappings}
                    geometries={geometries}
                    components={components}
                    canCreate={true}
                    onSaveGeometries={handleSaveGeometries}
                    onDeleteGeometry={handleDeleteGeometry}
                  />
                )}
                {sceneConfig && (
                  <SceneConfig
                    canEdit={true}
                    getThumbnail={getThumbnail}
                    onUpdateConfig={onUpdateSceneConfig}
                    sceneConfig={versionConfig}
                  />
                )}
              </Sidebar>
              {sceneConfig && <Screenshots />}
              {geometryMapping && <Geometries />}
            </ModelViewer>
          ) : null}
        </div>
      </BigModal>
      {showMappingModal ? (
        <SimpleModal
          className="clear-mappings-modal"
          title="Remove Version Mappings"
          onClose={() => setShowMappingModal(false)}
        >
          <div className="body">
            <div>Choose to remove all version mappings or mappings by type.</div>
            <div>
              {
                'If removing by type, and no other mapping types exist on the mapping, \
              it will be completely removed.'
              }
            </div>
            <div>
              {
                'If mappings were copied from a previous version, they will be retained \
              on that version.'
              }
            </div>
            <CustomSelect
              rawOptions={[
                { label: 'Remove all mappings', value: 'all' },
                { label: 'Remove model mappings', value: 'dbids' },
                { label: 'Remove custom mappings', value: 'geometryIds' },
              ]}
              isMulti={false}
              value={removeMappingScope}
              onChange={(value) => setRemoveMappingScope(value)}
            />
          </div>
          <div className="actions">
            <Button
              activity="secondary"
              onClick={() => setShowMappingModal(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={busy}
              onClick={handleRemoveMappings}
            >
              Remove
            </Button>
          </div>
        </SimpleModal>
      ) : null}
      {bimModalOpen && (
        <BimExtractModal
          onClose={handleClose}
          onSubmit={onSubmitBimData}
          onFormUpdate={onBimConfigChanged}
          bimComponents={bimData.bimComponents}
          componentsAllLevels={bimData.bimComponentsAllLevels}
          missingComponents={bimData.missingComponents}
          propertyNames={bimData.propertyNames}
          categoryNames={bimData.categoryNames}
          bimExtractConfig={bimExtractConfig}
          itemDesignationsCount={bimData.itemDesignationsCount}
          importingBimData={importingBimData}
        />
      )}
      {confirmationDialog ? (
        <ConfirmationDialog
          onCancel={() => setConfirmationDialog(null)}
          onConfirm={() => setConfirmed(true)}
          confirmType={confirmationDialog.confirmType}
          confirmText={confirmationDialog.confirmText}
          title={confirmationDialog.title}
          subtitle={confirmationDialog.subtitle}
          body={confirmationDialog.body}
          className="model-edit-modal-confirm"
          showCancel
        />
      ) : null}
    </>
  );
};

export default ModelEditModal;
